import { css } from "@emotion/react";
import { Table, useMantineTheme } from "@mantine/core"
import * as React from "react"
import { useState } from "react";
import Layout from "../components/layout";

// static data
const students = [
  { id: 0, firstName: "John", middleName: "Walter", lastName: "Jones" },
  { id: 1, firstName: "John", middleName: "Walter", lastName: "Jones" },
  { id: 2, firstName: "John", middleName: "Walter", lastName: "Jones" },
  { id: 3, firstName: "John", middleName: "Walter", lastName: "Jones" }
];

export default function Students(props) {
  // static mapped school data
  const studentRows = students.map((student) => (
    <tr key={student.id}>
      <td>{student.firstName}</td>
      <td>{student.middleName}</td>
      <td>{student.lastName}</td>
    </tr>
  ));

  // Table thread style
  const threadStyle = css({
    display: "table-header-group"
  });

  return (
    <Layout>
      <Table striped highlightOnHover withColumnBorders>
        <thread css={threadStyle}>
          <tr>
            <th>First Name</th>
            <th>Middle Name</th>
            <th>Last Name</th>
          </tr>
        </thread>
        <tbody>{studentRows}</tbody>
      </Table>
    </Layout>
  );
}